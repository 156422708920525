
import { defineComponent } from 'vue-demi';

export default defineComponent({
  name: "NewMain",
  data() {
    return {
      password: '',
      resetPassword: '',
    }
  },
  methods: {
    async onNew() {
      if (!this.password || !this.resetPassword) return;
      await this.API.auth.onChange({ password: this.password, resetPassword: this.resetPassword }, { Authorization: this.$.attrs.token });
      this.$router.push('/auth');
    },
  }
})
